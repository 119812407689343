.or {
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid rgb(245, 239, 239);
  line-height: 0.1em;
  margin: 25px 0;
}

// .facebook-btn {
//   font-weight: bold;
//   width: 100%;
//   color: #3b5998;
//   border-color: #3b5998 !important;
//   &:hover {
//     color: var(--light) !important;
//     background: #3b5998 !important;
//   }
// }
// .google-btn {
//   font-weight: bold;
//   width: 100%;
//   color: #dd4b39 !important;
//   border-color: #dd4b39 !important;
//   &:hover {
//     color: var(--light) !important;
//     background: #dd4b39 !important;
//   }
// }

.facebook-btn {
  display: flex;
  padding: 0;
  border: none;
}

.facebook-before {
  background: #0064ab;
  border: none;
  border-radius: 3px 0px 0px 3px;
  color: #f4f4f4;
  display: inline-block;
  // float: left;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.facebook-name {
  background: #0079ce;
  border: none;
  display: inline-block;
  border-radius: 0px 3px 3px 0px;
  color: #f4f4f4;
  height: 50px;
  line-height: 50px;
  text-transform: uppercase;
  width: 250px;
}

.google-btn {
  display: flex;
  padding: 0;
  border: none;
}

.google-before {
  background: #b92a17;
  border: none;
  border-radius: 3px 0px 0px 3px;
  color: #f4f4f4;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.google-name {
  background: #dd4b39;
  border: none;
  display: inline-block;
  border-radius: 0px 3px 3px 0px;
  color: #f4f4f4;
  // cursor: pointer;
  height: 50px;
  line-height: 50px;
  text-transform: uppercase;
  width: 250px;
}
