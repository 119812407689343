.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  transition: 1s;
  background: rgba(0, 0, 0, 0.1);
  > .modal {
    position: static;
    display: block;
  }
}
