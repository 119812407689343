// divider
.navbar {
  .divider {
    height: 1em;
    width: 2px;
    background: var(--light);
  }
}
.navbar.navbar-mobile {
  .divider {
    height: 2px;
    width: 12em;
    background: var(--dark);
  }
}

// nav mobile section
.navbar ul {
  position: flex;
  display: flex;
  flex-direction: row;
  li.m-first {
    order: 1;
  }
}
.navbar.navbar-mobile ul {
  display: flex;
  flex-direction: column;
  li a {
    margin-left: 0;
  }
  li.m-first {
    order: -1;
  }
}

// user info section
.navbar ul {
  li.auth-dynamic-button {
    order: 2;
  }
  .profile-card {
    a {
      color: #0095ff;
    }
    user-select: none;
    position: absolute;
    right: 0;
    top: 60px;
    background: var(--light);
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 1.5rem;
    .user-letter {
      width: 4rem;
      height: 4rem;
      font-size: 2rem;
      line-height: 4rem;
      margin-bottom: 0.5rem;
    }
  }
}

.navbar.navbar-mobile ul {
  li.auth-dynamic-button {
    .bx-caret-down {
      display: none;
    }
    > div > .user-letter {
      display: none;
    }
    .profile-card {
      top: 0;
      box-shadow: none;
      position: relative;
      user-select: none;
      display: flex !important;
      background: inherit;
      border-radius: 10px;
      padding: 1.5rem;
      .user-letter {
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
        line-height: 4rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
