.loader-wrapper {
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10001;
  transition: 0.5s;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  > .loader {
    position: static;
    display: block;
  }
}
