.user-letter {
  width: 2.5rem;
  height: 2.5rem;
  pointer-events: none;
  border-radius: 50%;
  background: var(--secondary);
  font-size: 1.25rem;
  color: #fff;
  text-align: center;
  line-height: 2.5rem;
}
